var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "wechat-container" }, [
      _vm.isTestVersion
        ? _c("p", { staticStyle: { color: "#f00000" } }, [
            _vm._v("测试环境不生成小程序码，绑定请联系开发人员"),
          ])
        : _c("p", { style: { color: _vm.isValid ? "#f00000" : "" } }, [
            _vm._v(
              _vm._s(
                !_vm.isValid
                  ? "首次登陆，请绑定微信小程序后使用"
                  : "二维码已失效，请点击更换"
              )
            ),
          ]),
      _c("img", {
        attrs: { src: _vm.qrImg, alt: "绑定小程序码" },
        on: { click: _vm.refreshQr },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }