import {qr} from '@/api/tool/wechat'
import {isBlank} from "@/utils/whale";

/**
 * 获取base64小程序码
 */
export function getWechatMiniQr(scene,page) {
  return new Promise((resolve, reject) => {
    if(isBlank(scene) || isBlank(page)){
      throw new Error("参数缺失");
    }
    let wxQrCodeUnlimited = {
      scene: scene,
      page: page
    }
    qr().then(res => {
      if(res.code === 200){
        resolve(res)
      }else {
        throw new Error("获取小程序码失败")
      }
    }).catch(error => {
      reject(error)
    })
  })
}
