<template>
  <div class="app-container">
    <div class="wechat-container">
      <p style="color:#f00000" v-if="isTestVersion">测试环境不生成小程序码，绑定请联系开发人员</p>
      <p v-else :style="{color: isValid ? '#f00000' : ''}">{{!isValid ? '首次登陆，请绑定微信小程序后使用' : '二维码已失效，请点击更换'}}</p>
      <img :src="qrImg" alt="绑定小程序码" @click="refreshQr">
    </div>
  </div>
</template>

<script>
import {getWechatMiniQr} from "@/store/modules/wechat";
import {isBlank} from "@/utils/whale";

export default {
  data() {
    return {
      qrImg: null,
      qrTimer: null,
      isValid: false,
      isTestVersion: process.env.NODE_ENV === 'development',
    }
  },
  created() {
    this.initQr();
  },
  methods: {
    //初始化小程序码
    initQr() {
      if(this.isTestVersion){
        this.qrImg = 'https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/defaultNoQr.png';
      }else {
        this.$store.dispatch("GetUserInfo").then((res) => {
          let scene = res.data.userId;
          let page = 'pages/login/bind';
          getWechatMiniQr(scene,page).then((r)=>{
            this.qrImg = `data:image/png;base64,${r.data}`
            this.bindStatus();
          });
        });
      }
    },
    refreshQr() {
      if(!this.isTestVersion){
        clearInterval(this.qrTimer);
        this.isValid = false;
        this.initQr();
      }
    },
    bindStatus(){
      let flag = 1;
      this.qrTimer = setInterval(()=>{
        this.$store.dispatch("GetUserInfo").then((res) => {
          flag++;
          if(flag >= 300){
            clearInterval(this.qrTimer);
            this.isValid = true;
          }
          if(res.code !== 200){
            clearInterval(this.qrTimer);
            this.isValid = true;
          }else if(res.code === 200 && !isBlank(res.data.openId)){
            clearInterval(this.qrTimer);
            this.msgSuccess('绑定成功');
            this.$router.push({ path: "/index" });
          }
        }).catch(() => {
          clearInterval(this.qrTimer);
          this.isValid = true;
        });
      },1000);
    }
  }
};
</script>
<style lang="scss" scoped>
  .app-container{
    width: 80%;
    background: #fff;
    margin: 20px auto;
    .wechat-container{
      width: 100%;
      text-align: center;
      padding: 20px 0;
      box-sizing: border-box;
      p{
        font-size: 20px;
      }
      img {
        height: 300px;
        cursor: pointer;
      }
    }
  }
</style>
